const setImageSources = image => {
  const {
    dataset: { src, srcSet }
  } = image;

  if (src) {
    image.setAttribute('src', src);
  }

  if (srcSet) {
    image.setAttribute('srcSet', srcSet);
  }

  image.setAttribute('data-action', '');
};

const setImagesAttr = images => {
  images.forEach(img => {
    setImageSources(img);
  });
};

const lazyImages = () => {
  const sections = [...document.querySelectorAll('section')];

  // Browser natively supports lazy images
  // if ('loading' in HTMLImageElement.prototype) {
  //   setImagesAttr(images);
  //   // if not, use intersection observer instead
  // } else
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window
  ) {
    const sectionObserver = new IntersectionObserver((entries, isObserving) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const section = entry.target;

          const images = [
            ...section.querySelectorAll('img[data-action="lazy-load"]')
          ];
          images.forEach(image => setImageSources(image));

          isObserving.unobserve(section);
        }
      });
    });

    sections.forEach(sectionEl => sectionObserver.observe(sectionEl));
    // Browser doesn't support either therefore load all images
  } else {
    const images = [
      ...document.querySelectorAll('img[data-action="lazy-load"]')
    ];
    setImagesAttr(images);
  }
};

/* IE11 doesn't support object fit therefore add it as a background image */
const objectFitImages = () => {
  const header = document.querySelector('#sub header');

  if (header) {
    const headerImg = header.querySelector('header img.object-cover');

    header.setAttribute(
      'style',
      `background: no-repeat url(${headerImg.getAttribute(
        'src'
      )}); background-size: cover; background-position: center center;`
    );

    headerImg.classList.add('opacity-0');
  }

  const coverImages = document.querySelectorAll('main img.object-cover');
  [...coverImages].forEach(img => {
    img.parentElement.setAttribute(
      'style',
      `background: no-repeat url(${img.getAttribute(
        'src'
      )}); background-size: cover; background-position: center center;`
    );

    img.classList.add('opacity-0');
  });
};

export { lazyImages, objectFitImages, setImageSources };
