import polyfills from './scripts/polyfills';
import { lazyImages, objectFitImages } from './scripts/images';
import menu from './scripts/menu';

require('./css/main.css');

const start = () => {
  lazyImages();
  menu();

  if (!('CSS' in window) || !CSS.supports('object-fit', 'cover')) {
    objectFitImages();
  }

  // if ('serviceWorker' in navigator) {
  //   window.addEventListener('load', () => {
  //     navigator.serviceWorker
  //       .register('./sw.js')
  //       .then(registration => {
  //         console.log('SW registered: ', registration);
  //       })
  //       .catch(registrationError => {
  //         console.log('SW registration failed: ', registrationError);
  //       });
  //   });
  // }
};

// mainly IE11
polyfills();

start();
