module.exports = {
	theme: {
		extend: {
			fontFamily: {
				sans: [
					'Open Sans',
					'-apple-system',
					'BlinkMacSystemFont',
					'"Segoe UI"',
					'Roboto',
					'"Helvetica Neue"',
					'Arial',
					'"Noto Sans"',
					'sans-serif',
					'"Apple Color Emoji"',
					'"Segoe UI Emoji"',
					'"Segoe UI Symbol"',
					'"Noto Color Emoji"'
				],
				serif: ['PT Serif', 'Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif']
			},
			colors: {
				red: {
					lighter: '#F94242',
					normal: '#EB1D27',
					darker: '#B50303'
				}
			},
			listStyleType: {
				circle: 'circle'
			},
			screens: {
				'2xl': '1536px'
			}
		},
		container: {
			center: true
		}
	},
	variants: {},
	plugins: []
}
