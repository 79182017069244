import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { addClass, removeClass } from './helpers';

import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config.js'

const fullConfig = resolveConfig(tailwindConfig)

const toggleMenu = (navIcon, menu) => {

  if (window.innerWidth >= Number.parseInt(fullConfig.theme.screens.lg.replace('px', ''), 10)) {
    return;
  }

  if (!navIcon.classList.contains('open')) {
    addClass(navIcon, 'open');
    addClass(menu, 'open');
    disableBodyScroll(menu);
  } else {
    removeClass(navIcon, 'open');
    removeClass(menu, 'open');
    enableBodyScroll(menu);
  }
};

const menuAction = (event, link, navIcon, menu) => {
  event.preventDefault();

  window.location = link;

  toggleMenu(navIcon, menu);
};

export default () => {
  const navIcon = document.querySelector('.nav-icon');
  const menu = document.querySelector('#menu');

  navIcon.addEventListener(
    'click',
    event => {
      event.preventDefault();
      toggleMenu(navIcon, menu);
    },
    false
  );

  //stop double click on iOS mobile
  const menuItems = [...document.querySelectorAll('#menu a')];

  menuItems.forEach(item => {
    const link = item.href;

    item.addEventListener('touchend', event => {
      menuAction(event, link, navIcon, menu);
    });

    item.addEventListener('click', event => {
      menuAction(event, link, navIcon, menu);
    });
  });
};
